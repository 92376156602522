@import 'ds/styles/includes/index.scss';

.variableSelector {
    margin-top: 4px;
    padding: 4px 12px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #f1f1f1;
}

.variableKey {
    background-color: var(--neutral-100);
    border-radius: $primary-radius;
    color: var(--neutral-600);
    font-size: 12px;
}

.variableButton {
    width: 100%;

    &:focus,
    &:active {
        background-color: transparent;
    }
}
