@import 'ds/styles/includes/index.scss';

.horizontal.tabs {
    box-shadow: inset 0 -1px var(--neutral-500);
}
.horizontal .tab {
    display: inline-block;
    margin: 0px 2px;
    padding-bottom: 4px;
}

.horizontal .active {
    border-bottom: 2px solid var(--primary-500);
    font-weight: bold;
    color: var(--primary-500);
}

// .vertical .tab {
//     cursor: pointer;
//     display: block;
//     margin: 0px 8px;
//     padding: 8px;
//     line-height: 30px;
// }
// .vertical .active {
//     color: var(--primary-500);
//     background-color: var(--primary-100);
//     border-radius: $primary-radius;
// }
