@import 'ds/styles/includes/index.scss';

.content {
    z-index: 1;
    text-align: left;
}

.content {
    margin-top: 8px;
    background-color: var(--neutral-100);
    border-radius: $primary-radius;
    padding-top: 8px;
    padding-bottom: 8px;
}

.content a {
    display: block;
    padding: 5px 16px;
    text-decoration: none;
    font-weight: 500;
    // color: var(--neutral-800);
    &:hover {
        color: white;
        background-color: var(--primary-500);
        // background-color: var(--neutral-200);
        opacity: 1;
    }
}

.arrow,
.arrow::before {
    position: absolute;
    width: 10px;
    height: 10px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);

    border-top: 1px solid var(--neutral-200);
    border-left: 1px solid var(--neutral-200);
}

:global([data-popper-placement^='top']) > .arrow {
    bottom: -6px;
}

:global([data-popper-placement^='bottom']) > .arrow {
    top: -6px;
}

:global([data-popper-placement^='left']) > .arrow {
    right: -6px;
}

:global([data-popper-placement^='right']) > .arrow {
    left: -6px;
}
