@import 'ds/styles/includes/index.scss';

.table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.loading {
    opacity: 0.5;
}

.th {
    border-bottom: 1px solid var(--neutral-200);
    line-height: 22px;
    padding: 4px 4px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    color: var(--neutral-600);
}

.td {
    line-height: 22px;
    padding: 2px 4px;
    border-bottom: 1px solid var(--neutral-200);
    max-width: 360px;
    overflow: hidden; // for long text overflow
    text-overflow: ellipsis;
    white-space: nowrap;
}

.footerCell {
    padding: 2px 8px;
}

.tr {
    &:hover {
        background-color: var(--neutral-100);
    }
}

.help {
    text-transform: none;
    font-weight: normal;
}
