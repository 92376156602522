@import 'ds/styles/includes/index.scss';

.wrapper {
    position: relative;
}
.thumbnail {
    vertical-align: top;
    max-height: 150px;
    max-width: 150px;
}

.closeBtn {
    position: absolute;
    top: 1px;
    right: 1px;
}
