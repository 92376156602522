@import 'ds/styles/includes/index.scss';

.label {
    display: block;
    color: var(--neutral-700);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    // font-weight: bold;
}
