@import 'ds/styles/includes/index.scss';

.label {
    @include breakpoint(md-and-up) {
        margin-right: 4px;
        min-width: 150px;
        // max-width: 200px;
        // flex: 0 0 20%;
    }
}
