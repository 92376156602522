@import 'ds/styles/includes/index.scss';

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 34px;
}

.switch.small {
    width: 34px;
    height: 20px;
}

.switch.medium {
    width: 40px;
    height: 24px;
}

.disabled {
    opacity: 0.5;
}

/* Hide default HTML checkbox */
.switch .input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--neutral-300);
    transition: 0.4s;
    border-radius: 34px;
}

.switch.small .slider {
    border-radius: 20px;
}

.switch.medium .slider {
    border-radius: 24px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--neutral-000);
    transition: 0.4s;
    border-radius: 50%;
}

.switch.small .slider:before {
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
}

.switch.medium .slider:before {
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
}

.checked {
    background-color: var(--primary-500);
}

.checked {
    box-shadow: 0 0 1px var(--primary-500);
}

.checked:before {
    transform: translateX(26px);
}

.switch.small .checked:before {
    transform: translateX(14px);
}

.switch.medium .checked:before {
    transform: translateX(16px);
}

.label {
    display: inline-block;
    vertical-align: middle;
}

.switch.small .label {
    font-size: 12px;
}

.switch.medium .label {
    font-size: 14px;
}
