@import 'ds/styles/includes/index.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    cursor: default;
    background-color: var(--color-overlay);
}

.dialog {
    background-color: var(--surface-forefront);
    box-shadow: rgb(0 0 0 / 20%) 0px 11px 15px -7px,
        rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px;
    border-radius: $primary-radius;
    text-align: left;
    padding: 10px 20px 20px 20px;
}

.container {
    padding-top: 100px;
}
