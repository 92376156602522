@import 'ds/styles/includes/index.scss';

.container {
    border: 1px solid var(--neutral-200);
    padding: 0px 8px;
    border-radius: $primary-radius;
    position: relative;
}


