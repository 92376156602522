@import 'ds/styles/includes/index.scss';

.inputWrapper {
    position: relative;
    display: flex;
    align-items: center;
}

.iconWrapper {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    align-items: center;
}

.input {
    width: 100%;
    min-height: 34px;
    padding: 6px 10px;
    border: 1px solid var(--neutral-300);
    border-radius: $primary-radius;
    @include focus-outline();
    &:hover {
        border: 1px solid var(--primary-500);
    }
    color: var(--neutral-800);
    background-color: transparent;
    &.readOnly {
        cursor: default;
        pointer-events: none; // Disable interactions
    }
}
.input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.multiEmailWrapper.readOnly {
    cursor: default;
    pointer-events: none; // Disable interactions
}
