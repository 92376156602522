@import 'ds/styles/includes/index.scss';

.wrapper {
    position: relative;
    max-width: 100%;
}

.container {
    width: 100%;
    padding: 2px 6px;
    border: 1px solid var(--neutral-300);
    border-radius: $primary-radius;
    @include focus-outline();
    &:hover {
        border: 1px solid var(--primary-500);
    }
    color: var(--neutral-800);
    background-color: transparent;
}

.x {
    position: absolute;
    right: 4px;
    border-radius: $primary-radius;
    background-color: transparent; //(--surface-forefront);
    margin: 2px;
    padding: 2px 6px;
}
.input {
    cursor: pointer;
    color: var(--neutral-800);
    background-color: transparent;
    border: none;
    width: 100%;
    line-height: 26px;
}
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.items {
    width: max-content;
    min-width: 100%;
    visibility: hidden;
    z-index: 100;
    background-color: var(--surface-forefront);
    padding: 8px 0px;
    margin: 4px 0px 0px 0px;
    border-radius: $primary-radius;
    position: absolute;
    max-height: 300px;
    overflow: scroll;
    box-shadow: $box-shadow;
}
.item {
    cursor: pointer;
    padding: 4px 10px;
    list-style-type: none;
}
.item[disabled] {
    color: var(--neutral-500);
    cursor: not-allowed;
}
.itemText {
    overflow: hidden;
    text-overflow: ellipsis;
}
.highlight {
    background-color: var(--neutral-200);
}

.isTitle {
    color: var(--neutral-800);
    font-weight: 500;
}
