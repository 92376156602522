@import 'ds/styles/includes/index.scss';

.main {
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.primary {
    color: var(--primary-500);
    &:hover {
        color: var(--primary-300);
    }
}

.secondary {
    color: var(--neutral-800);
    &:hover {
        color: var(--neutral-600);
    }
}

.success {
    color: var(--success-500);
    &:hover {
        color: var(--success-300);
    }
}

.danger {
    color: var(--danger-500);
    &:hover {
        color: var(--danger-300);
    }
}

.warning {
    color: var(--warning-500);
    &:hover {
        color: var(--warning-300);
    }
}

.neutral {
    color: var(--neutral-500);
    &:hover {
        color: var(--neutral-600);
    }
}
