@import 'ds/styles/includes/index.scss';

.avatar {
    font-weight: bold;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    color: white;
}

// .primary {
//     color: var(--primary-500);
//     background-color: var(--primary-100);
// }

// .secondary {
//     color: var(--secondary-500);
//     background-color: var(--secondary-100);
// }

// .success {
//     color: var(--success-500);
//     background-color: var(--success-100);
// }

// .danger {
//     color: var(--danger-500);
//     background-color: var(--danger-100);
// }
// .warning {
//     color: var(--warning-500);
//     background-color: var(--warning-100);
// }

// .neutral {
//     color: var(--neutral-600);
//     background-color: var(--neutral-200);
// }
