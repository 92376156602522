@import 'ds/styles/includes/index.scss';

.badge {
    font-weight: bold;
    border-radius: 12px;
    display: inline-block;
    // margin: 0px 2px;
    margin-right: 2px;
    padding: 0px 8px;
    font-size: 12px;
    line-height: 20px;
    font-family: $font-family;
}

.large {
    font-size: 14px;
    line-height: 24px;
    padding: 0px 12px;
}

.primary {
    color: var(--primary-500);
    background-color: var(--primary-100);
    &.clickable {
        &:hover {
            background-color: var(--primary-200);
        }
    }
}

.secondary {
    color: var(--secondary-500);
    background-color: var(--secondary-100);
    &.clickable {
        &:hover {
            background-color: var(--secondary-200);
        }
    }
}

.success {
    color: var(--success-500);
    background-color: var(--success-100);
    &.clickable {
        &:hover {
            background-color: var(--success-200);
        }
    }
}

.danger {
    color: var(--danger-500);
    background-color: var(--danger-100);
    &.clickable {
        &:hover {
            background-color: var(--danger-200);
        }
    }
}

.warning {
    color: var(--warning-500);
    background-color: var(--warning-100);
    &.clickable {
        &:hover {
            background-color: var(--warning-200);
        }
    }
}

.clickable {
    cursor: pointer;
}

.neutral {
    color: var(--neutral-600);
    background-color: var(--neutral-200);
    &.clickable {
        &:hover {
            background-color: var(--neutral-300);
        }
    }
}
