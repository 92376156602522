@import 'ds/styles/includes/index.scss';

.container {
    display: flex;
    flex-direction: column;
    font-family: $font-family;
}
.dropzone {
    flex: 1;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 2px dashed var(--neutral-200);
    background-color: var(--neutral-100);
    color: var(--neutral-500);
    outline: none;
    transition: border 0.24s ease-in-out;
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
}

// .thumbsContainer {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     margin-top: 16px;
// }

// .thumb = {
//     display: inline-flex;
//     border-radius: 2px;
//     border: 1px solid #eaeaea;
//     margin-bottom: 8px;
//     margin-right: 8px;
//     width: 100%;
//     height: 100%;
//     padding: 4px;
//     box-sizing: border-box;
// }

// .thumbInner {
//     display: flex;
//     min-width: 0;
//     overflow: hidden;
// }

// .img {
//     display: block;
//     width: auto;
//     height: 100%;
// }
