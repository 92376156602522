@import 'ds/styles/includes/index.scss';

.wrapper {
    position: relative;
}

.click {
    position: absolute;
    top: 0px;
    bottom: 0px;
    color: var(--neutral-600);
    padding-top: 50px;
    &:hover {
        // background-color: var(--neutral-200);
        // opacity: 0.5;
        // backdrop-filter: blur(1px);
    }
}
.right {
    text-align: right;
    right: 4px;
    margin-right: -20px;
}

.left {
    left: 4px;
    margin-left: -20px;
}
