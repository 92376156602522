@import 'ds/styles/includes/index.scss';

.textarea {
    line-height: 20px;
    width: 100%;
    padding: 6px 10px;
    border: 1px solid var(--neutral-300);
    border-radius: $primary-radius;
    @include focus-outline();
    background-color: transparent;
    color: var(--neutral-800);
    &:hover {
        border: 1px solid var(--primary-500);
    }
}
