@import 'ds/styles/includes/index.scss';

.select {
    width: 100%;
    padding: 6px 36px 6px 10px;
    border: 1px solid var(--neutral-300);
    border-radius: $primary-radius;
    @include focus-outline();
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    line-height: 20px;
    &:hover {
        border: 1px solid var(--primary-500);
    }
    background-color: transparent;
    color: var(--neutral-800);
}

.select:disabled {
    background: var(--neutral-300);
    opacity: 50%;
}
