@import 'ds/styles/includes/index.scss';

.horizontal {
    white-space: pre;
    button {
        border-radius: 0px;
        &:first-child {
            border-top-left-radius: $primary-radius;
            border-bottom-left-radius: $primary-radius;
        }
        &:not(:last-child) {
            border-right: none !important;
        }
        &:last-child {
            border-top-right-radius: $primary-radius;
            border-bottom-right-radius: $primary-radius;
        }
    }
}

.vertical {
    button {
        text-align: left;
        border-radius: 0px;
        display: block;
        width: 100%;
        margin-top: -1px;
        margin-bottom: -1px;
        &:first-child {
            border-top-left-radius: $primary-radius;
            border-top-right-radius: $primary-radius;
        }
        &:last-child {
            border-bottom-left-radius: $primary-radius;
            border-bottom-right-radius: $primary-radius;
        }
    }
}
